/* eslint-disable no-console */
import {
	GridContainer,
	GridItem,
	GridRow,
	TabPanel,
	Tabs as TabsFuse,
	gridConfig,
} from '@tvg/fuse-design';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { RequestParams } from '../../utils/types';
import { Branded, ComponentRenderer, HydratableRendering } from '../types';
import { TabContentFactory } from './tab-content-factory';
import { TabsComponent } from './types';

export const tabsSchemaId = 'https://theverygroup.com/dcx-templating/modules/tabs';
const StyledGridContainer = styled(GridContainer)`
	@media only screen and (max-width: ${gridConfig.m.maxWidth}) {
		&& {
			padding: 0;
		}
	}
`;
export const Tabs = ({
	tabListAriaLabel,
	tabs,
	// tabId,
	brand,
	params,
}: Branded<TabsComponent>): JSX.Element => {
	const [activeTab, setActiveTab] = useState<number>(1);
	useEffect(() => {
		const searchParams = new URLSearchParams(global?.window?.location.search);
		const activeTabValue = Number(searchParams.get('active-tab'));
		// below to be used for next iteration to enable tab panel unique id
		// const tabIdParamValue = searchParams.get('tab-id');
		// const isValidLandOnTab = activeTabValue && tabIdParamValue === tabId;

		if (activeTabValue && activeTabValue <= tabs.length) setActiveTab(activeTabValue);
	}, []);

	return (
		<StyledGridContainer>
			<GridRow>
				<GridItem xxs="12">
					<TabsFuse
						key={activeTab}
						tabListAriaLabel={tabListAriaLabel || 'Content Tabs'}
						landOnTab={activeTab}
					>
						{tabs.map(({ label, content }) => (
							<TabPanel key={label} label={label}>
								<TabContentFactory content={content} brand={brand} params={params} />
							</TabPanel>
						))}
					</TabsFuse>
				</GridItem>
			</GridRow>
		</StyledGridContainer>
	);
};
export const tabsRenderer: ComponentRenderer<TabsComponent> = (
	props: TabsComponent,
	brand: string,
	params?: RequestParams,
): HydratableRendering => ({
	component: <Tabs {...props} brand={brand} params={params} />,
	props: { ...props, brand, params },
	reference: 'Tabs',
	enableMetaSchema: true,
});
