import { GridContainer, gridConfig } from '@tvg/fuse-design';
import styled from 'styled-components';

export const StyledPageContentGridContainer = styled(GridContainer).withConfig({
	shouldForwardProp: (prop) => prop !== 'isStandardPageContent',
})`
	&& {
		@media only screen and (min-width: ${gridConfig.l.minWidth}) {
			${({ isStandardPageContent }) => !isStandardPageContent && `padding: 0;`}
		}
	}
`;
