import {
	ButtonGroup as ButtonGroupFuse,
	GridItem,
	GridRow,
	SpacingWrapper,
	TextBlock as TextBlockFuse,
	gridConfig,
} from '@tvg/fuse-design';
import React from 'react';
import styled from 'styled-components';
import buildGoogleAnalyticsTag from '../../utils/buildGoogleAnalyticsTag';
import buttonClickHandler from '../../utils/buttonClickHandler';
import richTextEditor from '../../utils/rich-text-editor/richTextEditor';
import { RequestParams } from '../../utils/types';
import { StyledPageContentGridContainer } from '../shared/styles';
import { Branded, ComponentRenderer, HydratableRendering } from '../types';
import backgroundConfig from './backgroundConfig';
import { ButtonGroupComponent, TextBlockComponent } from './types';

export const textBlockSchemaId = 'https://theverygroup.com/dcx-templating/modules/text-block';

// Equivalent to 10 columns. Required to keep text block background colour full width but content inside to reduce to 10 columns.
const customWidth = (10 / 12) * 100;

const StyledButtonGroup = styled(ButtonGroupFuse)`
	&& {
		padding-top: var(--spacing-global-space5);

		@media only screen and (min-width: ${gridConfig.m.minWidth}) {
			padding-top: var(--spacing-global-space7);
		}
	}
`;

const StyledGridItem = styled(GridItem)`
	&& {
		.fuse-text-block {
			> * {
				@media only screen and (min-width: ${gridConfig.l.minWidth}) {
					max-width: ${customWidth}%;
				}
			}

			.fuse-text-block--center-align {
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
`;

export const TextBlock = ({
	background,
	blackBorder = false,
	gridWrapper = true,
	paddingTop = false,
	paddingBottom = false,
	paddingLeft = false,
	paddingRight = false,
	text,
	params,
	brand,
	props,
	ctaWithVariant,
	ctaWrapper,
}: Branded<TextBlockComponent & ButtonGroupComponent>): JSX.Element => {
	const isStandardPageContent = params?.prefix === 'standard';

	const textBlockContent = (
		<>
			<TextBlockFuse
				background={background && backgroundConfig[background].propValue}
				paddingTop={paddingTop}
				paddingBottom={paddingBottom}
				paddingLeft={paddingLeft}
				paddingRight={paddingRight}
				blackBorder={blackBorder}
				dangerouslySetInnerHTML={{
					__html: richTextEditor(text, background),
				}}
			/>
			{!background && !blackBorder && ctaWithVariant && (
				<StyledButtonGroup
					brand={brand}
					link={{
						text: ctaWithVariant[0]?.text,
						href: ctaWithVariant[0]?.url,
						onClick: ctaWithVariant[0]?.publishEvent
							? () => buttonClickHandler(`${ctaWithVariant[0].publishEvent}`)
							: undefined,
					}}
					buttonVariant={ctaWithVariant[0]?.buttonVariant}
					secondaryLink={{
						text: ctaWithVariant.length === 2 ? ctaWithVariant[1].text : undefined,
						href: ctaWithVariant.length === 2 ? ctaWithVariant[1].url : undefined,
						onClick:
							ctaWithVariant.length === 2 && ctaWithVariant[1].publishEvent
								? () => buttonClickHandler(`${ctaWithVariant[1].publishEvent}`)
								: undefined,
					}}
					secondButtonVariant={ctaWithVariant[1]?.buttonVariant}
					dataAnalyticsId={
						ctaWithVariant && ctaWithVariant[0]?.text && ctaWithVariant[0].url
							? buildGoogleAnalyticsTag('ButtonGroup', ctaWithVariant[0].text)
							: undefined
					}
					dataAnalyticsIdSecondary={
						ctaWithVariant &&
						ctaWithVariant.length === 2 &&
						ctaWithVariant[1].text &&
						ctaWithVariant[1].url
							? buildGoogleAnalyticsTag('ButtonGroup', ctaWithVariant[1].text)
							: undefined
					}
					gridWrapper={false}
					{...props}
				/>
			)}
		</>
	);

	return gridWrapper ? (
		<SpacingWrapper marginBottom={ctaWithVariant && ctaWrapper ? 'm' : 'l'}>
			<StyledPageContentGridContainer isStandardPageContent={isStandardPageContent}>
				<GridRow>
					<StyledGridItem xxs="12">{textBlockContent}</StyledGridItem>
				</GridRow>
			</StyledPageContentGridContainer>
		</SpacingWrapper>
	) : (
		textBlockContent
	);
};
export const textBlockRenderer: ComponentRenderer<TextBlockComponent> = (
	props: TextBlockComponent,
	brand: string,
	params?: RequestParams,
): HydratableRendering => ({
	component: <TextBlock {...props} brand={brand} params={params} />,
	props: { ...props, brand },
	reference: 'TextBlock',
});
