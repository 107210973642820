import {
	ContentBanner2 as ContentBanner2Fuse,
	GridContainer,
	GridItem,
	GridRow,
	SpacingWrapper,
} from '@tvg/fuse-design';
import React from 'react';
import styled from 'styled-components';
import buildGoogleAnalyticsTag from '../../utils/buildGoogleAnalyticsTag';
import buttonClickHandler from '../../utils/buttonClickHandler';
import { ComponentRenderer, HydratableRendering } from '../types';
import { ContentBanner2Component } from './types';

export const contentBanner2SchemaId =
	'https://theverygroup.com/dcx-templating/modules/content-banner-2';

export const ContentBanner2 = ({
	variant,
	heading,
	subheading,
	paragraph,
	terms,
	cta,
}: ContentBanner2Component): JSX.Element => {
	const StyledGridContainer = styled(GridContainer)`
		&& {
			padding: 0;
		}
	`;

	const variantConfig: { [key: string]: string } = {
		Brand: 'brand',
		Black: 'black',
		'Black with Border': 'blackWithBorder',
	};

	return (
		<SpacingWrapper marginBottom="l">
			<StyledGridContainer>
				<GridRow>
					<GridItem xxs="12">
						<ContentBanner2Fuse
							variant={variantConfig[variant]}
							textContent={{
								heading: heading && heading.text,
								headingTag: 'h2',
								enhancedText: heading && heading.enhancedText,
								subHeading: subheading,
								subHeadingTag: 'h3',
								paragraph,
								legalText: terms,
							}}
							link={{
								text: cta && cta.text,
								href: cta && cta.url,
								onClick:
									cta && cta.publishEvent && (() => buttonClickHandler(`${cta.publishEvent}`)),
							}}
							dataAnalyticsId={
								cta && buildGoogleAnalyticsTag('ContentBanner2', heading?.text || cta?.text || '')
							}
						/>
					</GridItem>
				</GridRow>
			</StyledGridContainer>
		</SpacingWrapper>
	);
};

export const contentBanner2Renderer: ComponentRenderer<ContentBanner2Component> = (
	props: ContentBanner2Component,
): HydratableRendering => ({
	component: <ContentBanner2 {...props} />,
	props,
	reference: 'ContentBanner2',
});
