import {
	Accordion as AccordionFuse,
	AccordionGroup as AccordionGroupFuse,
	GridContainer,
	GridItem,
	GridRow,
	SpacingWrapper,
	TextBlock,
} from '@tvg/fuse-design';
import React from 'react';
import styled from 'styled-components';
import richTextEditor from '../../utils/rich-text-editor/richTextEditor';
import { RequestParams } from '../../utils/types';
// import { TextBlock } from '../text-block/text-block';
import { Branded, ComponentRenderer, HydratableRendering } from '../types';
import { AccordionGroupComponent } from './types';

export const accordionGroupSchemaId =
	'https://theverygroup.com/dcx-templating/modules/accordion-group';

const StyledTextBlock = styled(TextBlock)`
	&& {
		.fuse-text-block__text-size-accordion-group {
			padding: var(--spacing-global-space5) 0;
			font-size: var(--font-size-1);
			line-height: var(--font-line-height-1);
		}
	}
`;

export const AccordionGroup = ({
	accordions,
	title,
	description,
	hideBorder,
}: Branded<AccordionGroupComponent>): JSX.Element => {
	return (
		<SpacingWrapper marginBottom="l">
			<GridContainer>
				<GridRow>
					<GridItem xxs="12" l="10">
						<AccordionGroupFuse
							title={title}
							description={description}
							hideBorder={hideBorder}
							titleTag="h2"
						>
							{accordions.map(({ heading, content, meta }, i) => {
								let accordionText: string = content ? richTextEditor(content) : '';
								accordionText = accordionText.replace(
									/(<h4\b[^>]*>)(.*?)(<\/h4>)/g,
									`$1<span class="fuse-text-block__text-size-accordion-group">$2</span>$3`,
								);
								return (
									<AccordionFuse key={i} meta={meta} heading={heading} hideBorder>
										{content && (
											<StyledTextBlock
												paddingTop={false}
												paddingBottom={false}
												paddingLeft={false}
												paddingRight={false}
												dangerouslySetInnerHTML={{
													__html: accordionText,
												}}
											/>
										)}
									</AccordionFuse>
								);
							})}
						</AccordionGroupFuse>
					</GridItem>
				</GridRow>
			</GridContainer>
		</SpacingWrapper>
	);
};
export const accordionGroupRenderer: ComponentRenderer<AccordionGroupComponent> = (
	props: AccordionGroupComponent,
	brand: string,
	params?: RequestParams,
): HydratableRendering => ({
	component: <AccordionGroup {...props} brand={brand} params={params} />,
	props: { ...props, brand },
	reference: 'AccordionGroup',
});
